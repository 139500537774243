<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$basicSystemConfigBmglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
        <!-- <el-checkbox size="mini" style='margin-left:15px;' @change='tableKey=tableKey+1' v-model="showDescription">Id/描述</el-checkbox> -->
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;border: 1px solid #EBEEF5;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <div slot="header" class="clearfix">
              <el-button type="text" style="padding: 0 11px" @click="getAllOrgs">所有机构>></el-button>
            </div>
            <el-tree :data="orgsTree" :expand-on-click-node="false" default-expand-all :props="defaultProps"   @node-click="handleNodeClick"></el-tree>
          </el-card>
        </el-col>
        <el-col :span="20" style="height: 100%;">
          <div class="bg-white" style="height: 100%;">
            <el-table ref="mainTable" :key='tableKey' :data="list"  border fit highlight-current-row style="width: 100%;" height="calc(100% - 52px)" @row-click="rowClick" @selection-change="handleSelectionChange">
              <el-table-column type="selection" align="center" width="55"> </el-table-column>
              <el-table-column :label="'Id'" v-if="showDescription" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.id}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column :label="'层级ID'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="link-type" @click="handleUpdate(scope.row)">{{scope.row.cascadeId}}</span>
                </template>
              </el-table-column> -->
              <el-table-column :label="'机构名称'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.name}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'上级机构'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.parentName}}</span>
                </template>
              </el-table-column>
              <el-table-column :label="'创建时间'" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.createTime}}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="status-col" :label="'状态'" width="80">
                <template slot-scope="scope">
                  <span :class="scope.row.status | statusFilter">{{statusOptions.find(u =>u.key == scope.row.status).display_name}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" :label="'操作'" width="180" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
                  <!-- <el-button v-if="scope.row.status==0" size="mini" type="danger" @click="handleModifyStatus(scope.row,1)">停用</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
          </div>
        </el-col>
      </el-row>
      <!-- 编辑添加 -->
      <el-dialog v-el-drag-dialog class="dialog-mini" width="500px" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :rules="rules" ref="dataForm" :model="temp" label-position="right" label-width="100px">
          <!-- <el-form-item size="small" :label="'Id'" prop="id" v-show="dialogStatus=='update'">
            <span>{{temp.id}}</span>
          </el-form-item>
          <el-form-item size="small" :label="'层级ID'" v-show="dialogStatus=='update'">
            <span>{{temp.cascadeId}}</span>
          </el-form-item> -->
          
          <el-form-item size="small" :label="'机构名称'" prop="name">
            <el-input v-model="temp.name" maxlength="50" show-word-limit></el-input>
          </el-form-item>
          <el-form-item size="small" :label="'排序'" >
            <el-input-number  size="small" v-model="temp.sortNo" style="width: 100%;" :min="0" :precision="0" ></el-input-number>
            <!-- <el-input v-model="temp.name" maxlength="50" show-word-limit></el-input> -->
          </el-form-item>
          <el-form-item size="small" :label="'状态'">
            <el-select class="filter-item" v-model="temp.status" placeholder="请选择状态">
              <el-option v-for="item in  statusOptions" :key="item.key" :label="item.display_name" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="small" :label="'上级机构'">
            <treeselect ref="orgsTree" :options="orgsTrees" :default-expand-level="3" :multiple="false" 
              :open-on-click="true" :open-on-focus="true" :clear-on-select="true" :clearable="false" v-model="selectOrgs"></treeselect>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="mini" v-if="dialogStatus=='create'" type="primary" @click="createData">确认</el-button>
          <el-button size="mini" v-else type="primary" @click="updateData">确认</el-button>
        </div>
      </el-dialog>
      <!-- 添加角色用户 -->
      <el-dialog class="dialog-mini user-dialog" v-el-drag-dialog :title="'分配用户'" :visible.sync="roleUsers.dialogUserResource">
        <selectUsersCom
          ref="selectUser"
          v-if="roleUsers.dialogUserResource"
          :orgId="multipleSelection[0].id"
          :hiddenFooter="true"
          :loginKey="'loginUser'"
          :selectUsers.sync="roleUsers.rowIndex > -1 && roleUsers.list[roleUsers.rowIndex]">
        </selectUsersCom>
        <div style="text-align:right;" slot="footer">
          <el-button size="small" type="cancel" @click="roleUsers.dialogUserResource = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSaveUsers">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import {listToTreeSelect} from '@/utils'
  import * as orgs from '@/api/orgs'
  import * as apiUsers from '@/api/users'
  import * as login from '@/api/login'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import Pagination from '@/components/Pagination'
  import elDragDialog from '@/directive/el-dragDialog'
  import selectUsersCom from '@/components/SelectUsersCom'
  import extend from "@/extensions/delRows.js"
  export default {
    name: 'org',
    components: {
      Sticky,
      Treeselect,
      Pagination,
      selectUsersCom,
    },
    mixins: [extend],
    directives: {
      waves,
      elDragDialog
    },
    data() {

      return {
        orgsTrees:[],
        orgsTreesData: [],
        isFatherData:{},
        isFather: true,
        defaultProps: { // tree配置项
          children: 'children',
          label: 'label'
        },
        multipleSelection: [], // 列表checkbox选中的值
        tableKey: 0,
        list: [],
        subLists: [],
        total: 0,
        currentOrgId: '',
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          orgId: '',
          key: undefined
        },
        apps: [],
        statusOptions: [{
          key: 1,
          display_name: '停用'
        },
        {
          key: 0,
          display_name: '正常'
        }
        ],
        showDescription: false,
        orgs: [], // 用户可访问到的组织列表
        // orgsTree: [], // 用户可访问到的所有机构组成的树
        temp: {
          id: undefined,
          cascadeId: '',
          parentName: '',
          parentId: null,
          name: '',
          status: 0,
          sortNo:0,
        },
        dialogFormVisible: false,
        chkRoot: false, // 根节点是否选中
        treeDisabled: true, // 树选择框时候可用
        dialogStatus: '',
        textMap: {
          update: '编辑',
          create: '添加'
        },
        rules: {
          name: [{
            required: true,
            message: '机构名称不能为空',
            trigger: 'blur'
          }]
        },
        downloadLoading: false,
        selectOrgs: '',
        roleUsers: {
          dialogUserResource: false,
          Texts: '',
          rowIndex: -1,
          selectUsers: [],
          list: []
        }
      }
    },
    watch: {
      //处理通过tags关闭页面多选不清空的问题
      '$route'(){
        if(this.$store.state.isLeaveTags == true){
          this.multipleSelection = [];
          this.$refs.mainTable.clearSelection()
          this.$store.state.isLeaveTags = !this.$store.state.isLeaveTags
        }
      },
      selectOrgs() {
        this.temp.parentId = this.selectOrgs
        var parentname = this.orgsTreesData.find((val) => {
          return this.selectOrgs == val.id
        }).label
        this.temp.parentName = parentname
      }
    },
    computed: {
      orgsTree(){
        return this.$store.state.allInstitution
      },
      // mechOrgsTree() {
      //   // const arr = [{
      //   //   id: '',
      //   //   label: '根节点',
      //   //   parentId: null
      //   // }]
      //   return this.orgsTree
      // },
      // isRoot: {
      //   get() {
      //     return this.chkRoot
      //   },
      //   set(v) {
      //     this.chkRoot = v
      //     if (v) {
      //       this.temp.parentName = '根节点'
      //       this.temp.parentId = ''
      //     }
      //     this.treeDisabled = v
      //   }
      // }
    },
    filters: {
      statusFilter(status) {
        var res = 'color-success'
        switch (status) {
          case 1:
            res = 'color-danger'
            break
          default:
            break
        }
        return res
      }
    },
    created() {
    },
    mounted() {
      // this.getOrgTree()
     this.getLoadUsersOfTenant()
    },
    activated() {
      this.getList()
    },
    methods: {
      getLoadUsersOfTenant(){
        basicDataApi.GetLoadUsersOfTenant().then((res)=>{
         if(res.result.length>0){
          res.result.forEach((item)=>{
            item.value= item.id
            item.label =item.name
          })
          this.orgsTrees =listToTreeSelect(JSON.parse(JSON.stringify(res.result)))
          this.orgsTreesData = res.result
        }
        }).catch(()=>{

        })
      },
      // 重新加载机构
      upDateFzjg(){
        var data={
          page: 1,
          limit: 9999,
        }
        basicDataApi.getAllList(data).then(response => {
          if(response.code == 200){
            // console.log(response,11111)
            // 获取所有分支机构处理
            var Process = response.result.Org.map(function(item) {
              return {
                id: item.id,
                label: item.name,
                value: item.id,
                parentId: item.parentId || null
              }
            })
            var orgstmp = JSON.parse(JSON.stringify(Process)) 
            this.$store.state.allInstitution = listToTreeSelect(orgstmp)
           
          }
        }).catch(()=>{
          
        })
      },
      loadRoleUsers() {
        var _this = this
        this.isLoading = true
        apiUsers.LoadByOrg({ page: 1, limit: 99999, orgId: _this.multipleSelection[0].id }).then(response => {
          this.roleUsers.dialogUserResource = true
          _this.roleUsers.list[this.roleUsers.rowIndex] = response.data || []
        })
      },
      handleSaveUsers() {
        this.$refs.selectUser.handleSaveUsers()
        const arr = this.roleUsers.list[this.roleUsers.rowIndex]
        const postData = {
          orgId: this.multipleSelection[0].id,
          userIds: arr.length > 0 && arr.map(item => item.id) || []
        }
        orgs.AssignOrgUsers(postData).then(() => {
          this.$message.success('添加成功')
          this.roleUsers.dialogUserResource = false
        })
      },
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleNodeClick(data) {
        this.isFather =false
        this.listQuery.orgId= data.id
        this.isFatherData =data
        this.getList()
      },
      getAllOrgs() {
        this.listQuery.orgId=""
        this.getList()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd':
            this.handleCreate()
            if(this.isFather){
              let neworg =this.orgsTrees.filter((item)=>{ return item.parentId==""|| item.parentId== null})
              if(neworg.length>0){
                this.selectOrgs = neworg[0].id
                this.temp.parentId = neworg[0].id
                this.temp.parentName =neworg[0].name
              }
            }else{
              this.selectOrgs =  this.isFatherData.id
              this.temp.parentId =this.isFatherData.id
              this.temp.parentName =this.isFatherData.name
            }
          
            break
          case 'btnEdit':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleUpdate(this.multipleSelection[0])
            break
          case 'btnAssignOrgUser':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.roleUsers.rowIndex = this.list.findIndex(item => item.id === this.multipleSelection[0].id)
            this.loadRoleUsers()
            break
          case 'btnDel':
            if (this.multipleSelection.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelete(this.multipleSelection)
            break
          default:
            break
        }
      },
      getList() {
        login.getSubOrgs(this.listQuery).then(response => {
          if(response.code == '200'){
            this.subLists = Object.assign([], response.data)
            this.total = response.count
            this.pageSubOrgs()
          }else{
            this.$message({
              message:response.message,
              type:'warning'
            })
            this.subLists = []
          }
          this.$nextTick(() => {
            this.$refs.mainTable.doLayout();
          });
        }).catch(()=>{
          this.subLists = []
        })
      },
      pageSubOrgs() {
        this.list = this.subLists.slice((this.listQuery.page - 1) * this.listQuery.limit, this.listQuery.page * (this.listQuery.limit))
        this.roleUsers.selectUsers = this.list.map(() => { return { users: [], Texts: '' } })
      },
      // getOrgTree() {
      //   var _this = this // 记录vuecomponent
      //   login.getOrgs().then(response => {
      //     _this.orgs = response.result.map(function(item) {
      //       return {
      //         id: item.id,
      //         label: item.name,
      //         value: item.id,
      //         parentId: item.parentId || null
      //       }
      //     })
      //     var orgstmp = JSON.parse(JSON.stringify(_this.orgs))
      //     _this.orgsTree = listToTreeSelect(orgstmp)
      //   })
      // },
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.orgId =""
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.pageSubOrgs()
      },
      handleModifyStatus(row, status) { // 模拟修改状态
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        row.status = status
      },
      resetTemp() {
        this.temp = {
          id: undefined,
          cascadeId: '',
          parentName: '',
          parentId: '',
          name: '',
          sortNo:0,
          status: 0
        }
      },
      handleCreate() { // 弹出添加框
        this.resetTemp()
        this.dialogStatus = 'create'
        this.dialogFormVisible = true
        this.selectOrgs = ''
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() { // 保存提交
        if(!this.selectOrgs||this.selectOrgs==""){
          this.$message.error("请选择上级机构")
          return
        }
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$store.commit("updataListLoading",true)
            orgs.add(this.temp).then((response) => {
              // 需要回填数据库生成的数据
              this.temp.id = response.result.id
              this.temp.cascadeId = response.result.cascadeId
              this.list.unshift(this.temp)
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              // this.getOrgTree()
              this.upDateFzjg();//更新分支机构数据
              this.getList()
              this.getLoadUsersOfTenant()
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      handleUpdate(row) { // 弹出编辑框
        this.temp = Object.assign({}, row) // copy obj
        this.dialogStatus = 'update'
        this.dialogFormVisible = true
        this.selectOrgs = this.temp.parentId
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      updateData() { // 更新提交
        if(!this.selectOrgs||this.selectOrgs==""){
          this.$message.error("请选择上级机构")
          return
        }
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            const tempData = Object.assign({}, this.temp)
            this.$store.commit("updataListLoading",true)
            orgs.update(tempData).then(() => {
              this.dialogFormVisible = false
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success',
                duration: 2000
              })
              // this.getOrgTree()
              this.upDateFzjg();//更新分支机构数据
              for (const v of this.list) {
                if (v.id === this.temp.id) {
                  const index = this.list.indexOf(v)
                  this.list.splice(index, 1, this.temp)
                  break
                }
              }
              this.getList()
              this.getLoadUsersOfTenant()
              this.$store.commit("updataListLoading",false)
            }).catch(()=>{
              this.$store.commit("updataListLoading",false)
            })
          }
        })
      },
      getOrgTrees() {
        // var _this = this // 记录vuecomponent
        // login.getOrgs().then(response => {
        //   _this.orgs = response.result.map(function(item) {
        //     return {
        //       id: item.id,
        //       label: item.name,
        //       value: item.id,
        //       parentId: item.parentId || null
        //     }
        //   })
        //   var orgstmp = JSON.parse(JSON.stringify(_this.orgs))
        //   _this.orgsTree = listToTreeSelect(orgstmp)
        // })
        this.getList()
        this.upDateFzjg();//更新分支机构数据
      },
      handleDelete(rows) { // 多行删除
        this.delrows(orgs, rows, this.getOrgTrees)
      }
    },
  }

</script>

<style lang="scss" scoped>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .el-select.filter-item.el-select--small {
    width: 100%;
  }
  ::v-deep .user-dialog{
    .el-dialog{
      height: 70%;
      .el-icon-close{
        padding-top: 10px;
      }
      .el-dialog__body{
        height: calc(100% - 35px - 40px);
      }
      .el-dialog__headerbtn {
        top: 0;
      }
    }
  }

</style>
